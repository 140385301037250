@media screen and (max-width:800px){
  #commitments{
  
    h2{
      margin:50px 0;
    }
  
    .commitmentContainer{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
  
      .commitment{
        width:32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding:20px;
        background-color: var(--generalcolor);
        margin:4px;
        height:160px;
  
        &:nth-of-type(1), &:nth-of-type(4){
          border-radius: 5px 50px;
        }
  
        &:nth-of-type(2), &:nth-of-type(3){
          border-radius: 50px 5px ;
        }
  
  
        img{
          width:60px;
        }
  
        p{
          color:white;
          padding: 10px;
          font-size: 15px;
        }
      }
    }
  
  }
}

@media screen and (min-width:801px){
  #commitments{
  
    h2{
      margin:100px 0;
    }
  
    .commitmentContainer{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width:80%;
      margin:0 auto;
  
      .commitment{
        width:32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding:20px;
        background-color: var(--generalcolor);
        margin:4px;
        height:200px;
  
        &:nth-of-type(1), &:nth-of-type(4){
          border-radius: 5px 50px;
        }
  
        &:nth-of-type(2), &:nth-of-type(3){
          border-radius: 50px 5px ;
        }
  
  
        img{
          width:90px;
        }
  
        p{
          color:white;
          padding: 10px;
          font-size: 18px;
        }
      }
    }
  
  }
}