@media screen and (max-width:800px){
    .account{

        padding-bottom: 30px;
    
        h2{
            margin: 30px auto;
        }
    
        .userInformations{
            width: 90%;
            margin:40px auto;
    
            font-size: 15px;
    
            h3{
                font-weight: 500;
                color:var(--generalcolor);
            }
    
            .row{
                p{
                    text-overflow: ellipsis;
                    max-width:60%;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    
        input{
            width:60%;
            margin:10px auto;
        }
    
        .allOrders{
            margin-top: 40px;
    
            .summary{
                margin-top: 40px;
                border-radius: 5px;
    
                .send{
                    margin: 25px auto;
                }

                .summary{
                    margin-top: 0;
                }
            }
        }
    
        .send{
            margin:15px auto 50px auto;
        }
    }
}


@media screen and (min-width:801px){
    .account{

        padding-bottom: 30px;
    
        h2{
            margin: 30px auto;
        }
    
        .userInformations{
            width: 90%;
            margin:40px auto;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            font-size: 15px;
    
            h3{
                font-weight: 500;
                color:var(--generalcolor);
            }
    
            .row{
                p{
                    text-overflow: ellipsis;
                    max-width:60%;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    
        input{
            width:50%;
            margin:10px auto;
        }
    
        .allOrders{
            margin:40px auto 0 auto;
            width:50%;
    
            .summary{
                margin-top: 40px;
                border-radius: 5px;
    
                .send{
                    margin: 25px auto;
                }

                .summary{
                    margin-top: 0;
                }
            }
        }
    
        .send{
            width:20%;
            margin:15px auto 50px auto;
        }
    }
}