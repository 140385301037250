.apHome{
    
    form{
        .checkboxContainer{
            margin:60px auto 15px auto;
            height: 40px;
            width: 80%;
    
            label{
                font-size: 20px;
                height: 40px;
                line-height: 40px;
            }
    
            input{
                width:25px;
                height: 25px;
                margin:0;
                padding: 0;
                cursor:pointer;
            }
    
        }
    }
}