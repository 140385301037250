@media screen and (max-width:800px){
    .ProductHandlerForm{
        background-color: gainsboro;
        width: 30% !important;
        border-radius: 5px;
        margin: 50px auto;
        
    
        h2{
            font-size: 22px;
        }
    
        .imgContainer{
            margin: 30px auto;
            height:200px;
            width: 200px;
            border-radius: 50%;
            overflow: hidden;
            border:2px dashed var(--generalcolor);
            background-size:60%;
            background-repeat: no-repeat;
            background-position: center;
    
            label{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: var(--generalcolor);
                height: 20%;
                width: 80%;
                color:white;
                margin: 40% 10%;
                border-radius: 5%;
                visibility: hidden;
            }
    
            &:hover{
                label{
                    visibility: visible;
                }
            }
    
            input{
                display: none;
            }
        }
    
        select, input{
            margin:20px auto;
            outline: none;
        }
    
        .ingredientSelector{
            overflow: auto;
            height: auto;
            background-color: transparent;
            height:45px;
    
    
            option{
                height: 30px;
                padding: 9px 0 2px 10px; // To vertical center text for Firefox and Chrome
    
    
                &:checked{
                    outline: none;
                    background-color:var(--generalcolor);
                    background-image: linear-gradient(to right, var(--generalcolor), var(--generalcolor));
                    color:white;
                }
    
    
            }
    
        }
    
        .checkboxContainer{
            width: 88%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            margin:15px auto;
    
            label{
                margin: 0;
                padding: 0;
                width: 30%;
                text-align: left;
            }
    
            input[type="checkbox"]{
                margin:0;
                padding: 0;
                width: 30px;
                height: 30px;
                cursor: pointer;
            }
    
    
        }
    
        .dailyQuantitiesHandler{
            margin:30px auto;
            width: 85%;
            padding:20px;
            border-radius: 5px;
            border:2px solid var(--generalcolor);
    
            h4{
                margin:30px auto 50px auto;
                color:var(--generalcolor);
                font-size: 20px;
            }
    
            .dailyQuantity{
                display: flex;
                flex-direction: row;
                align-items:center;
                justify-content: space-between;
                margin:25px auto;
    
                &.title{
                    margin:25px auto 40px auto;
                    p{
                        font-weight: 500;
                    }
                }
    
                p{
                    text-align: center;
                    flex:1;
    
                    &.void{
                        flex:0.1;
                    }
                }
    
                button{
                    border:none;
                    background-color: var(--generalcolor);
                    color:white;
                    font-weight: 500;
                    padding:5px 12px;
                    border-radius: 5px;
                    font-size: 20px;
                    cursor: pointer;
                    text-align: center;
                }
    
    
                &.add{
                    input{
                        width: 17%;
                    }
                }
    
            }
        }
    
    }
}

@media screen and (min-width:801px){
    .ProductHandlerForm{
        background-color: gainsboro;
        width: 70%;
        border-radius: 5px;
        margin: 50px auto;
        
    
        h2{
            font-size: 22px;
        }
    
        .imgContainer{
            margin: 30px auto;
            height:200px;
            width: 200px;
            border-radius: 50%;
            overflow: hidden;
            border:2px dashed var(--generalcolor);
            background-size:60%;
            background-repeat: no-repeat;
            background-position: center;
    
            label{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: var(--generalcolor);
                height: 20%;
                width: 80%;
                color:white;
                margin: 40% 10%;
                border-radius: 5%;
                visibility: hidden;
            }
    
            &:hover{
                label{
                    visibility: visible;
                }
            }
    
            input{
                display: none;
            }
        }
    
        select, input{
            margin:20px auto;
            outline: none;
        }
    
        .ingredientSelector{
            overflow: auto;
            height: auto;
            background-color: transparent;
    
    
            option{
                height: 30px;
                padding: 9px 0 2px 10px; // To vertical center text for Firefox and Chrome
    
    
                &:checked{
                    outline: none;
                    background-color:var(--generalcolor);
                    background-image: linear-gradient(to right, var(--generalcolor), var(--generalcolor));
                    color:white;
                }
    
    
            }
    
        }
    
        .checkboxContainer{
            width: 88%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            margin:15px auto;
    
            label{
                margin: 0;
                padding: 0;
                width: 30%;
                text-align: left;
            }
    
            input[type="checkbox"]{
                margin:0;
                padding: 0;
                width: 30px;
                height: 30px;
                cursor: pointer;
            }
    
    
        }
    
        .dailyQuantitiesHandler{
            margin:30px auto;
            width: 85%;
            padding:20px;
            border-radius: 5px;
            border:2px solid var(--generalcolor);
    
            h4{
                margin:30px auto 50px auto;
                color:var(--generalcolor);
                font-size: 20px;
            }
    
            .dailyQuantity{
                display: flex;
                flex-direction: row;
                align-items:center;
                justify-content: space-between;
                margin:25px auto;
    
                &.title{
                    margin:25px auto 40px auto;
                    p{
                        font-weight: 500;
                    }
                }
    
                p{
                    text-align: center;
                    flex:1;
    
                    &.void{
                        flex:0.1;
                    }
                }
    
                button{
                    border:none;
                    background-color: var(--generalcolor);
                    color:white;
                    font-weight: 500;
                    height:30px;
                    width: 30px;
                    border-radius: 5px;
                    font-size: 20px;
                    cursor: pointer;
                }
    
    
                &.add{
                    input{
                        width: 17%;
                    }
                }
    
            }
        }
    
    }
}