@media screen and (max-width:800px){
  #ProductDetailPage{
    .imgContainer{
      width:80%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: #eaeaea;
      margin:30px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
  
      img{
        max-width:70%;
        height:70%;
      }
    }
  
    h1{
      color:var(--generalcolor);
      font-size: 25px;
      font-weight: 500;
      margin:30px 0;
    }
  
    .availableLines{
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      width:95%;
      margin:0 auto;
  
  
      label{
        flex:1;
        margin: 0 5px;
  
        input{
          display: none;
        }
  
        .lineChoiceContainer{
          border:1px solid var(--generalcolor);
          padding:10px 3px;
          background-color: #f2f2f2;
          border-radius: 5px;
          cursor: pointer;        
  
          p{
            font-size: 14px;
            color:var(--generalcolor);
            font-weight: 500;
          }
        }
  
        input:checked ~ .lineChoiceContainer{
          background-color: var(--generalcolor);
          p{color:white;}
        }
      }
    }

    .buyingTypeContainer{

      width:90%;
      margin:40px auto;

      .buyingTypeContent{
        background-color: #eaeaea;
        padding:20px;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: space-between;
        border-radius: 5px;


        .row{

          h3{
            font-weight: 500;
            font-size: 18px;
            color:var(--generalcolor);
          }
          
          select{
            width:60px;
            height:30px;
          }

          p{
            font-size: 18px;
          }
          

          .quantityCounter{
            display: flex;
            flex-direction: row;
            align-items: center;
            
            p{
              width:50px;
            }
            
            button{
              width:30px;
              height:30px;
              border:1px solid black;
              border-radius: 3px;
              cursor: pointer;
            }
          }

        }

      }
    }
  
    .send, .noStock{
      width:55%;
      margin:30px auto 70px auto;
      border-radius: 200px;
    }

    .noStock{
      color:red;
      font-size: 18px;
      font-weight: 500;
    }
  
    .ingredientsContainer{
  
      h2{
        font-weight: 500;
        margin:0;
      }
  
      .ingredient{
        width:90%;
        margin:40px auto;
        display: flex;
        flex-direction: row;
  
        .ingredientLeft{
          width:25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
          img{
            width: 100%;
          }
  
          h3{
            font-size: 15px;
            color: var(--generalcolor);
            margin-top: 5px;
          }
        }
  
        .description{
          width:75%;
          text-align: justify;
          padding-left: 25px;
          font-size: 15px;
        }
  
      }
  
    }
  }
}

@media screen and (min-width:801px){

  #ProductDetailPage{

    .imgContainer{
      width:30%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: #eaeaea;
      margin:50px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
  
      img{
        max-width:80%;
        height:80%;
      }
    }
  
    h1{
      color:var(--generalcolor);
      font-size: 24px;
      font-weight: 500;
      margin:50px 0;
    }
  
    .availableLines{
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      width:50%;
      margin:0 auto;
  
  
      label{
        flex:1;
        margin: 0 5px;
  
        input{
          display: none;
        }
  
        .lineChoiceContainer{
          border:1px solid var(--generalcolor);
          padding:10px 3px;
          background-color: #f2f2f2;
          border-radius: 5px;
          cursor: pointer;        
  
          p{
            font-size: 17px;
            color:var(--generalcolor);
            font-weight: 500;
          }
        }
  
        input:checked ~ .lineChoiceContainer{
          background-color: var(--generalcolor);
          p{color:white;}
        }
      }
    }

    .buyingTypeContainer{

      width:30%;
      margin:80px auto;


      .buyingTypeContent{
        background-color: #eaeaea;
        padding:20px;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: space-between;
        border-radius: 5px;


        .row{

          h3{
            font-weight: 500;
            font-size: 18px;
            color:var(--generalcolor);
          }
          
          select{
            width:60px;
            height:30px;
          }

          p{
            font-size: 18px;
          }
          

          .quantityCounter{
            display: flex;
            flex-direction: row;
            align-items: center;
            
            p{
              width:50px;
            }
            
            button{
              width:30px;
              height:30px;
              border:1px solid black;
              border-radius: 3px;
              cursor: pointer;
            }
          }

        }

      }
    }
  
    .send, .noStock{
      width:15%;
      height: 50px;
      margin:0px auto 140px auto;
      border-radius: 200px;
    }

    .noStock{
      color:red;
      font-size: 18px;
      font-weight: 500;
    }
  
    .ingredientsContainer{
  
      h2{
        font-weight: 500;
        margin:0;
      }
  
      .ingredient{
        width:50%;
        margin:70px auto;
        display: flex;
        flex-direction: row;
        align-items: center;
  
        .ingredientLeft{
          width:25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
          img{
            width: 50%;
          }
  
          h3{
            font-size: 18px;
            color: var(--generalcolor);
            margin-top: 25px;
          }
        }
  
        .description{
          width:75%;
          text-align: justify;
          padding-left: 25px;
          font-size: 18px;
          line-height: 1.5;
        }
  
      }
  
    }
  }
}