@media screen and (max-width:800px){
  #ProductsListPage{

    text-align: center;
  
    h1{
      color:var(--generalcolor);
      font-size: 22px;
      padding:30px 0;
    }
  

    p{
      font-size: 17px;
      margin:40px auto;
      font-weight: 400;
    }

    .productsList{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width:95%;
      margin:0 auto;
  
      .product{
        background-color: #eaeaea;
        border-radius: 5px;
        width: 46%;
        margin: 20px 2%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        box-shadow: 0 3px 3px 3px rgb(221, 221, 221);
  
        .imgContainer{
          height:150px;
          width:150px;
          border-radius: 50%;
          background-color: #dddddd;
          background-size: auto 90%;
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 20px;
        }
  
        .description{
          font-size: 15px;
          width:90%;
          text-align: justify;
          font-weight: 300;
          margin:0;
          padding: 0;
          overflow: hidden;
          height:55px;
        }
  
        .row{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width:90%;
          margin:10px 0;
          align-items: center;
          padding:0;
          height: 50px;

  
          .title, .price, .weight{
            color:var(--generalcolor);
            font-weight: 600;
          }

            
          .title, .price{
            font-size: 19px;
            text-align: left;
          }

          .title{
            max-width:65%;
            overflow: hidden;
            height: 25px;
            line-height: 25px;
            font-size: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .weight{
            text-align: right;
            width: 35%;
          }

          .price{
            flex:1;
          }
  
          .seeButton{
            border: none;
            cursor: pointer;
            padding: 5px 0;
            background-color: var(--generalcolor);
            color:white;
            font-size: 18px;
            border-radius: 5px;
            width:50%;
          }
          
        }
  
      }
    }
  }
}

@media screen and (min-width:801px){
  #ProductsListPage{

    text-align: center;
  
    h1{
      color:var(--generalcolor);
      font-size: 24px;
      padding:50px 0;
      font-weight: 500;
    }
  

    p{
      font-size: 17px;
      margin:40px auto;
      font-weight: 400;
    }

    .productsList{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      width:95%;
      margin:0 auto;
  
      .product{
        background-color: #eaeaea;
        border-radius: 5px;
        width: 21%;
        margin: 40px 2%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
        box-shadow: 0 3px 3px 3px rgb(221, 221, 221);
  
        .imgContainer{
          height:200px;
          width:200px;
          border-radius: 50%;
          background-color: #dddddd;
          background-size: auto 90%;
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 20px;
        }
  
        .description{
          font-size: 15px;
          width:90%;
          text-align: justify;
          font-weight: 300;
          margin:0;
          padding: 0;
          overflow: hidden;
          height:50px;
          font-size: 18px;
        }
  
        .row{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width:90%;
          margin:30px 0;
          align-items: center;
          padding:0;
          height: 50px;

  
          .title, .price, .weight{
            color:var(--generalcolor);
            font-weight: 600;
            font-size: 20px;
          }

            
          .title, .price{
            text-align: left;
          }

          .title{
            max-width:70%;
            overflow: hidden;
            height: 25px;
            line-height: 25px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .weight{
            text-align: right;
            width: 30%;
          }

          .price{
            flex:1;
            font-size: 22px;
          }
  
          .seeButton{
            border: none;
            cursor: pointer;
            padding: 5px 0;
            background-color: var(--generalcolor);
            color:white;
            font-size: 18px;
            border-radius: 5px;
            width:50%;
            height: 30px;
            line-height: 30px;
          }
          
        }
  
      }
    }
  }
}