@media screen and (max-width:800px){
    .alertModalBackground{
        height:100vh;
        width:100%;
        background-color: rgba(0,0,0,0.7);
        position: fixed;
        z-index: 100;
        top:0;
        left:0;
    
        .alertModal{
            height: 250px;
            width:90%;
            background-color: var(--backcolor);
            margin:200px auto 100px auto;
            padding:20px 0;
            border-radius: 5px;
    
            p{
                height:130px;
                padding:30px 10px;
                margin: 10px auto;
                width:90%;
                overflow-y: auto;
            }
    
            .send{
                width:140px;
                height:40px;
                margin:0;
            }
    
        }
    
    }
}

@media screen and (min-width:801px){
    .alertModalBackground{
        height:100vh;
        width:100%;
        background-color: rgba(0,0,0,0.7);
        position: fixed;
        z-index: 100;
        top:0;
        left:0;
    
        .alertModal{
            height: 230px;
            width:30%;
            background-color: var(--backcolor);
            margin:100px auto;
            padding:20px 0;
            border-radius: 5px;
    
            p{
                height:110px;
                padding:30px 10px;
                margin: 10px auto;
                width:90%;
                overflow-y: auto;
            }
    
            .send{
                width:140px;
                height:40px;
                margin:0;
            }
    
        }
    
    }
}