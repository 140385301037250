@keyframes messageAppear{
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes wheelTurning{
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}


@media screen and (max-width:800px){
    .wheelGame{

        .wheelGameRowContainer{
    
            display: flex;
            flex-direction: column;
            width:90%;
            align-items: center;
            justify-content: flex-start;
            margin:30px auto;
    
            .imgContainer{
                width: 300px;
                height:300px;

                margin: 50px auto 0 auto;
    
                img{
                    width: 300px;
                    height:300px;
                    transform: rotate(0deg);
    
                    
                    &.turning{
                        animation: wheelTurning 1s 0s 5 linear;
                    }
                }
    
                .imgOverlay{
                    opacity: 0;
                    background-color: white;
                    position: absolute;
                    display: block;
                    margin-top: 85px;
                    margin-left: 25px;
                    height:130px;
                    width:250px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    z-index: 1;
    
                    p{
                        width: 90%;
                        line-height: 1.5;
                        font-size: 16px;
                        text-align: justify;
                    }
    
                    &.visible{
                        animation:messageAppear 0.1s linear forwards;
                    }
                }
            }
    
            .wheelGameContent{
                h2{
                    padding:10px 0 40px 0;
                    font-size: 22px;
                    font-weight: 500;
                }
    
                p{
                    font-size: 17px;
                    width:90%;
                    margin: 0 auto;
                    line-height: 1.5;
                    text-align: justify;
    
                    .bold{
                        display: inline-block;
                        font-weight: 500;
                        font-size: 18px;
                        margin-top: 30px;
                    }
                }
    
                .send{
                    width:60%;
                    margin-bottom: 50px;
                    &:disabled{
                        background-color: gray;
                        border-color: gray;
                        cursor:default;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:801px){
    .wheelGame{

        .wheelGameRowContainer{
    
            display: flex;
            flex-direction: row;
            width:80%;
            align-items: center;
            justify-content: space-between;
            height:85dvh;
            margin:0 auto;
    
            .imgContainer{
                width: 400px;
                height:400px;
    
                img{
                    width: 400px;
                    height:400px;
                    transform: rotate(0deg);
    
                    
                    &.turning{
                        animation: wheelTurning 2s 5s ease-out forwards, wheelTurning 1s 0s 5 linear;
                    }
                }
    
                .imgOverlay{
                    opacity: 0;
                    background-color: white;
                    position: absolute;
                    display: block;
                    margin-top: 145px;
                    margin-left: 50px;
                    height:110px;
                    width:300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    z-index: 1;
    
                    p{
                        width: 90%;
                        line-height: 1.5;
                        font-size: 18px;
                    }
    
                    &.visible{
                        animation:messageAppear 0.1s linear forwards;
                    }
                }
            }
    
            .wheelGameContent{
                h2{
                    padding:0 0 50px 0;
                    font-size: 24px;
                    font-weight: 500;
                }
    
                p{
                    font-size: 17px;
                    width:90%;
                    margin: 0 auto;
                    line-height: 1.2;
    
                    .bold{
                        display: inline-block;
                        font-weight: 500;
                        font-size: 18px;
                        margin-top: 30px;
                    }
                }
    
                .send{
                    width:26%;
                    &:disabled{
                        background-color: gray;
                        border-color: gray;
                        cursor:default;
                    }
                }
            }
        }
    }
}