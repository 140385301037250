@media screen and (max-width:800px){
    .wheelGameHome{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin:50px auto 30px auto;
        width:90%;
    
        img{
            width:250px;
            height: 250px;
            margin:30px 0;
        }
    
        .wheelGameHomeContent{
    
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
    
    
            h2{
                color:var(--generalcolor);
                font-size: 25px;
                margin:20px 0 50px 0;
            }
        
            p{
                font-size: 18px;
                line-height: 1.5;
                width: 100%;
                text-align: justify;
                padding: 0;
                margin: 0;

                span.center{
                    display: block;
                    text-align: center;
                    margin:0;
                }
            }
    
            .send{
                margin: 50px 0 20px 0;
                width: 40%;
            }
        }
    
    }
}

@media screen and (min-width:801px){
    .wheelGameHome{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin:80px auto 30px auto;
        width:70%;
        height: 400px;
    
        img{
            width:400px;
            height: 400px;
            margin:30px 0;
        }
    
        .wheelGameHomeContent{
    
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 85%;
    
    
            h2{
                color:var(--generalcolor);
                font-size: 25px;
                margin:0;
            }
        
            p{
                font-size: 18px;
                line-height: 1.5;
                width: 80%;
                text-align: center;
                padding: 0;
                margin: 0;
            }
    
            .send{
                margin: 0;
            }
        }
    
    }
}