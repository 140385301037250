@media screen and (max-width:800px){
  #LabelPresentation{
    background-image: url("../images//banner/banner-background.jpg");
    background-position-y:bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:30px 0;
    margin:30px 0;
    width: 100%;
  
  
    .labelCardContainer{
      height:200px;
      width:80%;
      margin:30px 0;
      border-radius: 10px;
      //filter: blur(5px);
      backdrop-filter: blur(10px);
  
      .labelCard{
        z-index: 1;
        height:100%;
        width:100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(233,233,233, 0.20);
  
        img{
          width: 80px;
          height:80px;
        }
  
        h3{
          color:white;
          font-weight: 500;
          margin-top: 25px;
          font-size: 18px;
        }
      }
  
    }
  
  }
}

@media screen and (min-width:801px){
  #LabelPresentation{
    background-image: url("../images//banner/banner-background-desktop.jpg");
    background-position-y:bottom;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:80px 0;
    margin:50px 0;
    width: 100%;
    justify-content: space-around;
  
  
    .labelCardContainer{
      height:500px;
      width:25%;
      margin:30px 0;
      border-radius: 10px;
      //filter: blur(5px);
      backdrop-filter: blur(10px);
  
      .labelCard{
        z-index: 1;
        height:100%;
        width:100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(233,233,233, 0.20);
  
        img{
          width: 150px;
          height:150px;
        }
  
        h3{
          color:white;
          font-weight: 500;
          margin-top: 45px;
          font-size: 22px;
        }
      }
  
    }
  
  }
}