@keyframes spinnerAnimation{
  from {transform:rotate(0deg)}
  to {transform:rotate(360deg)}
}

@media screen and (max-width:800px){
  #SplashScreen{
    width:100%;
    height:100vh;
    padding:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
  
    img{
      width:50%;
      margin-top: 40px;
    }
  
    h1{
      color:var(--generalcolor);
      margin:50px 0 30px 0;
      font-size: 20px;
    }
  
    p{
      font-size: 17px;
      margin:0 30px;
    }
  
    .spinner{
      border:10px solid var(--generalcolor);
      border-bottom: 10px solid transparent;
      height:75px;
      width:75px;
      margin-top: 40px;
      border-radius: 50%;
      animation:spinnerAnimation 1s linear infinite;
    }
  }

  .spinner{
    border:10px solid var(--generalcolor);
    border-bottom: 10px solid transparent;
    height:75px;
    width:75px;
    margin-top: 40px;
    border-radius: 50%;
    animation:spinnerAnimation 1s linear infinite;
  }
}

@media screen and (min-width:801px){
  #SplashScreen{
    width:100%;
    height:100vh;
    padding:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
  
    img{
      width:30%;
      margin-top: 40px;
    }
  
    h1{
      color:var(--generalcolor);
      margin:50px 0 30px 0;
      font-size: 20px;
    }
  
    p{
      font-size: 17px;
      margin:0 30px;
    }
  
    .spinner{
      border:10px solid var(--generalcolor);
      border-bottom: 10px solid transparent;
      height:75px;
      width:75px;
      margin-top: 40px;
      border-radius: 50%;
      animation:spinnerAnimation 1s linear infinite;
    }
  }

  .spinner{
    border:10px solid var(--generalcolor);
    border-bottom: 10px solid transparent;
    height:75px;
    width:75px;
    margin-top: 40px;
    border-radius: 50%;
    animation:spinnerAnimation 1s linear infinite;
  }
}