@media screen and (max-width:800px){
  #FoodSelector{

    padding:60px 0 50px 0;
  
    h1{
      font-size: 22px;
      color:var(--generalcolor);
      width:90%;
      margin:0 auto;
    }
  
  
    h2{
      color:black;
      font-weight: 400;
      font-size: 20px;
      margin-top: 40px;
    }
  
    .choiceContainer{
  
      display: flex;
      flex-direction: row;
      height:110px;
      justify-content: center;
      width:90%;
      margin:20px auto;
  
  
      label{
  
        margin: 0 2px;
        height:100%;
                
        &:first-of-type{
          .foodSelectorChoice{border-radius: 10px 0 0 10px;}
        }
  
        &:last-of-type{
          .foodSelectorChoice{border-radius: 0 10px 10px 0;}  
        }
  
        flex:1;
  
        input{
          display: none;
        }
  
        .foodSelectorChoice{
  
          background-color: var(--secondarycolor);
          height:100%;
          width:100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height:100%;
    
          img{
            height: 53px;
            margin:5px 0;
          }
    
          p{
            color:white;
            margin:0;
            margin:5px 0;
            font-size: 20px;
          }
        }
      }
    
    
      input:checked ~ .foodSelectorChoice{
        background-color: var(--generalcolor);
        p{
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  
  }
}

@media screen and (min-width:801px){
  #FoodSelector{

    padding:120px 0 50px 0;
  
    h1{
      font-size: 24px;
      color:var(--generalcolor);
      width:90%;
      margin:0 auto;
    }
  
  
    h2{
      color:black;
      font-weight: 400;
      font-size: 22px;
      margin-top: 50px;
    }
  
    .choiceContainer{
  
      display: flex;
      flex-direction: row;
      height:130px;
      justify-content: center;
      width:40%;
      margin:20px auto;
  
  
      label{
  
        margin: 0 2px;
        height:100%;
                
        &:first-of-type{
          .foodSelectorChoice{border-radius: 10px 0 0 10px;}
        }
  
        &:last-of-type{
          .foodSelectorChoice{border-radius: 0 10px 10px 0;}  
        }
  
        flex:1;
  
        input{
          display: none;
        }
  
        .foodSelectorChoice{
  
          background-color: var(--secondarycolor);
          height:100%;
          width:100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height:100%;
          cursor: pointer;
    
          img{
            height: 60px;
            margin:5px 0;
          }
    
          p{
            color:white;
            margin:0;
            margin:5px 0;
            font-size: 20px;
          }
        }
      }
    
    
      input:checked ~ .foodSelectorChoice{
        background-color: var(--generalcolor);

        p{
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  
  }
}