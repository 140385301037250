#Deliver{

    #MRWidget{
        width: 98%;
        margin:30px auto;
    
        .MRW-Search{
    
            padding-left: 0;
            background-position: 50% 20px;
            padding-top: 100px;
            background-size: 50px;
    
            .MRW-ButtonContainer{
                margin:20px auto 40px auto;
                margin-left: 30%;
            }
    
            .PR-AutoCplCity{
                top:170px;
            }
    
            .MRW-Line{
                margin-left: 0;
                width: 100%;
                margin:0;
            }
        } 
    
    }

    #parcelShopCode{
       /*  height: 0;
        opacity: 0; */
    }
    
}