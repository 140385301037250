@media screen and (max-width:800px){
  .landingMessage{
    font-size: 17px;
    background-color: var(--generalcolor);
    margin:0;
    color:white;
    height:50px;
    font-weight: 400;
    text-align: center;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding:0 8px;
    width: 100%;
  }
}

@media screen and (min-width:801px){
  .landingMessage{ 
    font-size: 18px; 
    background-color: var(--generalcolor);
    margin:0;
    color:white;
    height:50px;
    font-weight: 500;
    text-align: center;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding:0 8px;
    width: 100%;
  }
}