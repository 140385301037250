@media screen and (max-width:800px){
    .apPromos{

        .send{
            width:25%;
            margin: 30px auto;
        }
    
        form{
    
    
            label{
                display: block;
                margin-top: 20px;
            }
        
            .promopercentinput{
                margin:30px auto 0 auto;
                width:70%;
            }
    
            .row{
                align-items: center ;
                justify-content: space-between;
                width: 85%;
            }
    
            .isGlobalInputContainer{
                margin:30px auto;
                height: 40px;
    
                label{
                    font-size: 18px;
                    height: 40px;
                }
    
                input{
                    width:25px;
                    height: 25px;
                    margin:0;
                    padding: 0;
                    cursor:pointer;
                }
    
            }
        }

        .send{
            width:55%;
        }
    
        input{
            width: 65%;
            margin-top: 40px;
        }
    
        #modifyPromoForm{
            background-color: gainsboro;
            padding:20px;
            margin:40px auto 50px auto;
            width:80%;
            border-radius: 5px;
    
            form{
                input:disabled{
                    opacity: 80%;
                }
        
                .row{
                    margin:10px auto;
                    width: 90%;
                }
        
                .send{
                    margin-top: 50px;
                    width: 50%;
                }
            }
        }
    
        p{
            margin-top: 40px !important;
        }
    
        .apPromoItem{
            background-color: gainsboro;
            width: 80%;
            padding:20px;
            border-radius: 5px;
            margin:40px auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            .row{
                width:100%;
                align-items: center;
                justify-content: space-between;
    
                h4{
                    font-weight: 400;
                    font-size: 17px;
    
                    &:first-of-type{
                        color:var(--generalcolor);
                        font-weight: 500;
                        text-align: left;
                    }

                    &:last-of-type{
                        text-align: right;
                    }
                }
            }
    
            .apPromoItemButtonsContainer{
                width: 90%;
                margin-top: 30px;
    
                .send{
                    width: 42%;
                    margin:0px auto;
                }
            }
    
            
    
        }
    }
}

@media screen and (min-width:801px){
    .apPromos{

        .send{
            width:25%;
            margin: 30px auto;
        }
    
        form{
    
    
            label{
                display: block;
                margin-top: 20px;
            }
        
            .promopercentinput{
                margin:30px auto 0 auto;
                width:50%;
            }
    
            .row{
                align-items: center ;
                justify-content: space-between;
            }
    
            .isGlobalInputContainer{
                margin:30px auto;
                height: 40px;
    
                label{
                    font-size: 18px;
                    height: 40px;
                }
    
                input{
                    width:25px;
                    height: 25px;
                    margin:0;
                    padding: 0;
                    cursor:pointer;
                }
    
            }
        }
    
        input{
            width: 30%;
            margin-top: 40px;
        }
    
        #modifyPromoForm{
            background-color: gainsboro;
            padding:20px;
            margin:40px auto 50px auto;
            width:70%;
            border-radius: 5px;
    
            form{
                input:disabled{
                    opacity: 80%;
                }
        
                .row{
                    margin:10px auto;
                    width: 90%;
                }
        
                .send{
                    margin-top: 50px;
                    width: 50%;
                }
            }
        }
    
        p{
            margin-top: 40px;
        }
    
        .apPromoItem{
            background-color: gainsboro;
            width: 50%;
            padding:20px;
            border-radius: 5px;
            margin:40px auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            .row{
                width:100%;
                align-items: center;
                justify-content: space-between;
    
                h4{
                    font-weight: 400;
                    font-size: 19px;
    
                    &:first-of-type{
                        color:var(--generalcolor);
                        font-weight: 500;
                    }
                }
            }
    
            .apPromoItemButtonsContainer{
                width: 60%;
                margin-top: 30px;
    
                .send{
                    width: 40%;
                    margin:20px auto;
                }
            }
    
            
    
        }
    }
}