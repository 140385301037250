@media screen and (max-width:800px){
    #LoginPage{
        h2{
            margin:50px auto 30px auto;
            font-size: 24px;
        }
    
        .send{
            margin-bottom: 40px;
        }
    
        p{
            margin:20px auto;
            text-align: center;
            width:80%;
        }
    
        .other{
            margin-top: 20px;
            border:none;
            cursor: pointer;
            background-color: transparent;
            text-decoration: underline;
            color:black;
        }
    }
}

@media screen and (min-width:801px){
    #LoginPage{

        display: flex;
        flex-direction: column;
        align-items: center;

        h2{
            margin:50px auto 30px auto;
            font-size: 24px;
        }
    
        .send{
            margin-bottom: 40px;
        }
    
        p{
            margin:20px auto;
            text-align: center;
            width:80%;
        }
    
        .other{
            margin-top: 20px;
            border:none;
            cursor: pointer;
            background-color: transparent;
            text-decoration: underline;
            color:black;
        }
    }
}