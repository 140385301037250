#IntroVideoContainer{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    .IntroVideoOverlay{
      position: absolute;
      width:70px;
      height:70px;
      background-color: gainsboro;
      border:2px solid var(--generalcolor);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex:1;
  
      button{
        width: 50%;
        height:50%;
        cursor: pointer;
        border:none;
        text-align: center;
        padding:0;
        background-color: transparent;
  
        :hover{
          background-color: unset;
        }
  
        img{
          height:100%;
          width: 100%;
  
          &.paused{
            margin-left: 3px;
          }
        }
      }
    }
    
    #IntroVideo{
      width:100%;
      flex:2;
  
    }
  }