@import "./splashScreen.scss";

.paymentDonePage{
    p{
        margin:40px auto;
        color:var(--generalcolor);
        font-size: 18px;
        width:80%;
        font-weight: 500;
    }
    
    .spinner{
        margin: 50px auto;
    }
}