@media screen and (max-width:800px){
    .apUsers{
        .apUserItem{
            background-color: rgb(216, 216, 216);
            margin:40px auto;
            width:94%;
            padding:10px 0;
            border-radius: 5px;
    
            .row{
                width: 90%;
                margin:15px auto;
                padding:10px;
                align-items: center;
    
                h5{
                    padding: 0;
                    margin:0;
                    font-size: 17px;
                    font-weight: 400;
    
                    &:first-of-type{
                        font-weight: 500;
                        color:var(--generalcolor);
                    }
                }
    
            }
    
            h3{
                color:var(--generalcolor);
                font-size: 20px;
            }
    
            .apOrders{
                margin:2px auto;
                border-radius: 5px;
                width: 90%;
                max-height: 600px;
                padding:0 10px;
                overflow-y: auto;
    
                .apOrderItem{
                    width: 100%;
                    border: 1px solid gray;
    
                    .summary{
                        width:100%;
    
                        .cartItem{
                            width:87%;
                            margin:20px auto;

                            h5{
                                font-size: 15px;
                                color:black;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:801px){
    .apUsers{
        .apUserItem{
            background-color: rgb(216, 216, 216);
            margin:40px auto;
            width:60%;
            padding:10px 0;
            border-radius: 5px;
    
            .row{
                width: 90%;
                margin:10px auto;
                padding:10px;
                align-items: center;
    
                h5{
                    padding: 0;
                    margin:0;
                    font-size: 18px;
                    font-weight: 400;
    
                    &:first-of-type{
                        font-weight: 500;
                        color:var(--generalcolor);
                    }
                }
    
            }
    
            h3{
                color:var(--generalcolor);
                font-size: 20px;
            }
    
            .apOrders{
                margin:50px auto;
                border-radius: 5px;
                width: 90%;
                max-height: 600px;
                padding:0 10px;
                overflow-y: auto;
    
                .apOrderItem{
                    width: 100%;
                    border: 1px solid gray;
    
                    .summary{
                        width:90%;
    
                        .cartItem{
                            width:80%;
                            margin:20px auto;
                        }
                    }
                }
            }
        }
    }
}