@media screen and (max-width:800px){
  #tutorial{
    padding:0 10%;
  
    h2{
      padding:65px 0 35px 0;
    }
  
    .tutorialStep{
      
      display: flex;
      justify-content: space-between;
      align-items:center;
      padding:50px 0;
  
      border-bottom: 4px dashed var(--generalcolor);
  
      &:nth-of-type(odd){
        flex-direction: row;
        border-left: 4px dashed var(--generalcolor);
        h3{
          margin-left: -26px;
        }
      }
  
      &:nth-of-type(even){
        flex-direction: row-reverse;
        border-right: 4px dashed var(--generalcolor);
        h3{
          margin-right: -26px;
        }
      }
      
      &:last-of-type{
        border-bottom: none;
      }
  
      h3{
        height:50px;
        width:50px;
        background-color: var(--generalcolor);
        border-radius: 200px;
        text-align: center;
        line-height: 50px;
        color:white;
      }
      
      p{
        width:80%;
        font-size: 17px;
  
      }
    }
  }
  
  
}

@media screen and (min-width:801px){
  #tutorial{
    padding:0 20%;
  
    h2{
      padding:120px 0 65px 0;
    }
  
    .tutorialStep{
      
      display: flex;
      justify-content: space-between;
      align-items:center;
      padding:80px 0;
  
      border-bottom: 6px dashed var(--generalcolor);
  
      &:nth-of-type(odd){
        flex-direction: row;
        border-left: 6px dashed var(--generalcolor);
        h3{
          margin-left: -26px;
        }
        p{
          text-align: left;
        }
      }
  
      &:nth-of-type(even){
        flex-direction: row-reverse;
        border-right: 6px dashed var(--generalcolor);
        h3{
          margin-right: -26px;
        }
        p{
          text-align: right;
        }
      }
      
      &:last-of-type{
        border-bottom: none;
      }
  
      h3{
        height:50px;
        width:50px;
        background-color: var(--generalcolor);
        border-radius: 200px;
        text-align: center;
        line-height: 50px;
        color:white;
      }
      
      p{
        width:95%;
        font-size: 20px;
        font-weight: 400;

      }
    }
  }
  
  
}