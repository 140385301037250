@media screen and (max-width:800px){
    .apProductItem{
        width:85%;
        margin: 50px auto;
        padding:30px 10px;
        background-color: gainsboro;
        border-radius: 5px;
    
    
        .imgContainer{
            margin: 30px auto;
            height:200px;
            width: 200px;
            border-radius: 50%;
            overflow: hidden;
            border:2px dashed var(--generalcolor);
            background-size:60%;
            background-repeat: no-repeat;
            background-position: center;
            
    
            label{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: var(--generalcolor);
                height: 20%;
                width: 80%;
                color:white;
                margin: 40% 10%;
                border-radius: 5%;
                visibility: hidden;
            }
    
            &:hover{
                label{
                    visibility: visible;
                }
            }
    
            input{
                display: none;
            }
        }
    
        h3{
            color:var(--generalcolor);
            margin:40px auto 70px auto;
        }
    
        .apProductItemTitle{
            padding:100px auto;
            font-size: 20px;
            width:90%;
        }
    
        .row{
            width:100%;
            margin:10px auto;
            align-items: center;
            height: 50px;
    
            h4{
                width:20%;
                color:var(--generalcolor);
                text-align: left;
                font-size: 16px;
            }
    
            p{
                width:60%;
                text-align: right;
                text-overflow:ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin: 0;
            }
    
            &.longText{
                p{
                    cursor: pointer;
                }
            }
    
            &.buttonsContainer{
                width:90%;
                margin:20px auto 30px auto;
                button{
                    width: 45%;
                }
            }
    
        }
    
    }
}

@media screen and (min-width:801px){
    .apProductItem{
        width:60%;
        margin: 100px auto;
        padding:30px 50px;
        background-color: gainsboro;
        border-radius: 5px;
    
    
        .imgContainer{
            margin: 30px auto;
            height:200px;
            width: 200px;
            border-radius: 50%;
            overflow: hidden;
            border:2px dashed var(--generalcolor);
            background-size:60%;
            background-repeat: no-repeat;
            background-position: center;
            
    
            label{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: var(--generalcolor);
                height: 20%;
                width: 80%;
                color:white;
                margin: 40% 10%;
                border-radius: 5%;
                visibility: hidden;
            }
    
            &:hover{
                label{
                    visibility: visible;
                }
            }
    
            input{
                display: none;
            }
        }
    
        h3{
            color:var(--generalcolor);
            margin:40px auto 70px auto;
        }
    
        .apProductItemTitle{
            padding:100px auto;
    
        }
    
        .row{
            width:100%;
            margin:30px auto;
            align-items: center;
            height: 50px;
    
            h4{
                width:20%;
                color:var(--generalcolor);
                text-align: left;
            }
    
            p{
                width:60%;
                text-align: right;
                text-overflow:ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin: 0;
            }
    
            &.longText{
                p{
                    cursor: pointer;
                }
            }
    
            &.buttonsContainer{
                width:60%;
                button{
                    width: 40%;
                }
            }
    
        }
    
    }
}