@media screen and (max-width:800px){
  #Banner{
    margin:0;
    width:100%;
    background-image: url("../images//banner/banner-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    &.spring{
      background-image: url("../images/banner/banner-background.jpg");
    }

    &.summer{
      background-image: url("../images/banner/banner-background-summer.jpg");
    }

    &.autumn{
      background-image: url("../images/banner/banner-background-autumn.jpg");
    }

    &.winter{
      background-image: url("../images/banner/banner-background-winter.jpg");
    }

    &.halloween{
      background-image: url("../images/banner/banner-background-halloween.jpg");
    }

    &.christmas{
      background-image: url("../images/banner/banner-background-christmas.jpg");
    }
  
  
    .bannerContainer{
  
      width:100%;
      height:100%;
      background-color: rgba(0,0,0,0.4);
      display: flex;
      flex-direction: column;
      align-items: center;

      .bannerRowContainer{
        
        .bannerImage{
          display: none;
        }

        .bannerContent{
          .bannerImage{
            display: none;
          }
      
          h1{
            margin: 0;
            font-size: 22px;
            font-weight:400;
            padding:35px 15px;
            color:white;
          }
      
          .bannerImage{
            margin:0 auto;
            width:50%;
            display: block;
          }
      
          #frenchFlag{
            height:9px;
            width:80%;
            margin:45px auto;
            display: flex;
            flex-direction: row;
            
      
            *{
              height:9px;
              flex:1;
            }
            
            .blue{background-color: #244bb6; border-radius: 5px 0 0 5px;}
            .white{background-color: white;}
            .red{background-color: #D60920;; border-radius:0 5px 5px 0;}
          }
      
          #AnimalSelector{
            width: 80%;
            height:140px;
            background-color: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 10px auto 60px auto;
      
            .animal{
              background-color: var(--generalcolor);
              width:140px;
              border-radius: 5px;
              height: 80px;
              padding:10px 0 50px 0;
              
              img{
                width:70px;
                height:70px;
              }
      
              p{
                color:white;
                font-size: 20px;
              }
      
            }
      
          }
        }
      }
  
      .arrowContainer{
        background-color: transparent;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 30px;
  
        .arrowLeft{
          background-color: transparent;
          border-top: 40px solid var(--backcolor) ;
          border-left: 70px solid transparent;
          width:50%;
          height:100%;
          transform: rotate(180deg);
        }
  
        .arrowRight{
          background-color: transparent;
          border-top: 40px solid var(--backcolor) ;
          border-right: 70px solid transparent;
          width:50%;
          height:100%;
          transform: rotate(180deg);
        }
  
      }
  
    }
  }
}

@media screen and (min-width:801px){
  #Banner{
    margin:0;
    width:100%;
    background-image: url("../images/banner/banner-background-desktop.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    &.spring{
      background-image: url("../images/banner/banner-background-desktop.jpg");
    }

    &.summer{
      background-image: url("../images/banner/banner-background-desktop-summer.jpg");
    }

    &.autumn{
      background-image: url("../images/banner/banner-background-desktop-autumn.jpg");
    }

    &.winter{
      background-image: url("../images/banner/banner-background-desktop-winter.jpg");
    }

    &.halloween{
      background-image: url("../images/banner/banner-background-desktop-halloween.jpg");
    }

    &.christmas{
      background-image: url("../images/banner/banner-background-desktop-christmas.jpg");
    }

  
    .bannerContainer{
  
      width:100%;
      height:100%;
      background-color: rgba(0,0,0,0.4);
      display: flex;
      flex-direction: column;
      align-items: center;

  
      .bannerRowContainer{

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 100px 0;
        width: 70%;

        .bannerImage{
          margin:0;
          width: 400px;
          height: 400px;
          display: block;
        }

        .bannerContent{

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .bannerImage{
            margin:0;
            width: 400px;
            height: 400px;
            display: none;
          }

          h1{
            margin: 0;
            font-size: 30px;
            font-weight:400;
            padding:0 15px 40px 15px;
            color:white;
            font-weight: 400;
          }
      
          #frenchFlag{
            height:12px;
            width:40%;
            margin:20px 0;
            display: flex;
            flex-direction: row;
            
      
            *{
              height:20px;
              flex:1;
            }
            
            .blue{background-color: #244bb6; border-radius: 5px 0 0 5px;} 
            .white{background-color: white;} 
            .red{background-color: #D60920;; border-radius:0 5px 5px 0;} 
          }
      
          #AnimalSelector{ 
            width: 45%;
            height:180px; 
            background-color: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            margin-top: 40px;
            gap:15px;
      
            .animal{
              background-color: var(--generalcolor);
              width:170px;
              border-radius: 5px;
              height: 80px;
              padding:10px 0 50px 0;
              
              img{
                width:70px;
                height:70px;
              }
      
              p{
                color:white;
                font-size: 20px;
              }
      
            }
      
          }
        }

      }
  
      .arrowContainer{
        background-color: transparent;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 30px;
  
        .arrowLeft{
          background-color: transparent;
          border-top: 40px solid var(--backcolor) ;
          border-left: 70px solid transparent;
          width:50%;
          height:100%;
          transform: rotate(180deg);
        }
  
        .arrowRight{
          background-color: transparent;
          border-top: 40px solid var(--backcolor) ;
          border-right: 70px solid transparent;
          width:50%;
          height:100%;
          transform: rotate(180deg);
        }
  
      }
  
    }
  }
}