@media screen and (max-width:800px){
  body{
    width:100%;
    margin:0;
    padding:0;
    --backcolor:#f7f7f7;
    background-color: var(--backcolor);
    --generalcolor: #98551D;
    --secondarycolor:#d69865;
    --stepCircleColor:#eabf87;
  }
  
  .App {
    text-align: center;
    width:100%;
    background-color: var(--backcolor);
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    margin:0;
    overflow: hidden;
    //background-image: url("data:image/svg+xml,%3Csvg width='26' height='13' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2398551d' fill-opacity='0.04'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    //background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2398551d' fill-opacity='0.04' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  
  }
  h1,h2,h3,h4{
    margin:0;
  }
  
  input, select, textarea{
    outline:none;
    padding:0 8px 0 60px ;
    width:70%;
    height:40px;
    margin:15px 0;
    border:2px solid var(--generalcolor);
    border-radius: 5px;
    text-align: left;
    font-size: 17px;
    background-color: transparent;
  
    background-repeat: no-repeat, no-repeat;
    background-size: 25px 25px, 40px 45px;
    background-position-y:center, center;
    background-position-x: 7px, left;

    &[name="none"]{
      background-repeat: no-repeat, no-repeat;
      background-size: 0;
      background-position-y:0;
      background-position-x: 0;
      padding:0 8px;
      width: unset;
    }
    
  
    &[name="company"]{
      background-image: url("../images/inputs/company-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="email"]{
      background-image: url("../images/inputs/mail-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="tel"]{
      background-image: url("../images/inputs/phone-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="password"]{
      background-image: url("../images/inputs/password.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="name"]{
      background-image: url("../images/inputs/user-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="adress"]{
      background-image: url("../images/inputs/useradress-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="cardcode"]{
      background-image: url("../images/inputs/cardcode-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="cardexpiry"]{
      background-image: url("../images/inputs/cardexpiry-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="cardcvv"]{
      background-image: url("../images/inputs/cvv-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    &[name="promotioncode"]{
      background-image: url("../images/inputs/promotion-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="delivery"]{
      background-image: url("../images/inputs/delivery.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="generalmessage"]{
      background-image: url("../images/inputs/general-message.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="search"]{
      background-image: url("../images/inputs/search.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="text"]{
      background-image: url("../images/inputs/text.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }
  
    background-image: linear-gradient(to right, var(--generalcolor), var(--generalcolor));
  }

  select{

    background-image: url("../images/inputs/text.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    height: 45px;
    -webkit-appearance: none;
    width:96%;
    color:black;
  }

  textarea{
    background-color: transparent;
    background-image: url("../images/inputs/text.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    border:2px solid var(--generalcolor);
    border-radius: 5px;
    width: 75%;
    resize: vertical;
    margin:18px auto;
    font-size: 17px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    padding: 8px 8px 8px 60px;
    outline: none;
    background-size: 25px 25px, 40px 100%;
    height:90px;

  }
  
  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    margin:20px 0;
  }
  
  h2{
    font-size: 20px;
    color:var(--generalcolor);
  }
  
  button{
    &.send{
      background-color: var(--generalcolor);
      color:white;
      font-size: 17px;
      font-weight: 500;
      height:45px;
      border-radius: 5px;
      width:65%;
      margin-top:50px;
      border:2px solid var(--generalcolor);
      cursor: pointer;
      border-radius: 30px;
    }
  }
  
  a{
    text-decoration: none;
    color:black;
  
    &.send{
      display: inline-block;
      background-color: var(--generalcolor);
      color:white;
      font-size: 16px;
      font-weight: 500;
      height:40px;
      border-radius: 5px;
      margin-top:40px;
      border:2px solid var(--generalcolor);
      cursor: pointer;
      line-height: 40px;
      padding:0 20px;
      border-radius: 30px;

    }
  }
  
  p{
    font-size: 16px;
    line-height: 1.3;
    margin:0;
  }
  
  #GeneralPage{
    height:100%;
    width:100%;
    background-color: transparent;
  }
  
  
  
  
  
  #introduction{
    text-align: left;
    padding:50px 20px;
    background-color: transparent;
  
    h2{
      background-color: transparent;
      color:var(--generalcolor);
      font-weight: 500;
    }
  
    p{
      background-color: transparent;
      text-align: justify;
      padding:20px 0;
    }
  
    img{
      width:100%;
      border-radius: 5px;
    }
  }
  
  
  .lpgenericdiv{
    text-align: center;
    padding:30px 20px;
  
    h2{
      color:var(--generalcolor);
      font-weight: 500;
    }
    
    img{
      width:120px;
      height:120px;
      margin:50px 0;
      display:none;
    }

    h2 + img{
      display: inline-block;
    }
  
    p{
      text-align: justify;
      padding:0;
  
      &.annotation{
        font-weight: 300;
        font-size: 14px;
      }
    }
  
    li{
      margin:15px 0;
      text-align: left;
      font-size: 16px;
    }
  
    .mainColor{
      color:var(--generalcolor);
      font-weight: 600;
    }
  
  
    &.promos{
      background-color: rgb(230, 230, 230);
    }
  
    &.pros{
      p{
        text-align: center;
        margin:40px 0;
        line-height: 1.2;
      }
  
      img{
        margin:40px 0 10px 0;
      }
    }
  
    form{
      margin:0 10px;
  
    }
  }
  
  footer{
    margin-top: 50px;
    padding: 30px 20px;
    background-color: var(--generalcolor);
  
    h2{
      color:white;
      font-size: 20px;
    }
  
    h3{
      color:white;
      font-size: 16px;
    }
    
    p{
      color:white;
      font-size: 17px;
      text-align: left;
      margin:30px 0;
      left:50%;
      position: relative;
      margin-left: -25%;
      line-height: 20px;
  
      img{
        height: 20px;
        width:20px;
        margin:10px 15px -4px 0;
      }
    }
  }
  
  
}

@media screen and (min-width:801px){
  body{
    width:100%;
    margin:0;
    padding:0;
    --backcolor:#f7f7f7;
    background-color: var(--backcolor);
    --generalcolor: #98551D;
    --secondarycolor:#b88052;
    --stepCircleColor:#eabf87;
  }

  .App {
    text-align: center;
    width:100%;
    background-color: var(--backcolor);
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    margin:0;
    overflow: hidden;
    //background-image: url("data:image/svg+xml,%3Csvg width='26' height='13' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2398551d' fill-opacity='0.04'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    //background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2398551d' fill-opacity='0.04' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");

  }
  h1,h2,h3,h4{
    margin:0;
  }

  input, select, textarea{
    outline:none;
    padding:0 8px 0 60px ;
    width:20%;
    height:40px;
    margin:15px 0;
    border:2px solid var(--generalcolor);
    border-radius: 5px;
    text-align: left;
    font-size: 17px;
    background-color: transparent;

    background-repeat: no-repeat, no-repeat;
    background-size: 25px 25px, 40px 100%;
    background-position-y:center, center;
    background-position-x: 7px, left;
    
    &[name="none"]{
      background-repeat: no-repeat, no-repeat;
      background-size: 0;
      background-position-y:0;
      background-position-x: 0;
      padding:0 8px;
      width: unset;
    }

    &[name="company"]{
      background-image: url("../images/inputs/company-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="email"]{
      background-image: url("../images/inputs/mail-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="tel"]{
      background-image: url("../images/inputs/phone-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="password"]{
      background-image: url("../images/inputs/password.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }


    &[name="name"]{
      background-image: url("../images/inputs/user-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="adress"]{
      background-image: url("../images/inputs/useradress-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="cardcode"]{
      background-image: url("../images/inputs/cardcode-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="cardexpiry"]{
      background-image: url("../images/inputs/cardexpiry-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="cardcvv"]{
      background-image: url("../images/inputs/cvv-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="promotioncode"]{
      background-image: url("../images/inputs/promotion-white.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="delivery"]{
      background-image: url("../images/inputs/delivery.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="generalmessage"]{
      background-image: url("../images/inputs/general-message.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="search"]{
      background-image: url("../images/inputs/search.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    &[name="text"]{
      background-image: url("../images/inputs/text.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    }

    background-image: linear-gradient(to right, var(--generalcolor), var(--generalcolor));


  }

  select{

    background-image: url("../images/inputs/text.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    height: 45px;
    -webkit-appearance: none;
    width:89%;
    color:black;
  }

  textarea{
    background-color: transparent;
    background-image: url("../images/inputs/text.png"), linear-gradient(to right, var(--generalcolor), var(--generalcolor));
    border:2px solid var(--generalcolor);
    border-radius: 5px;
    width: 80%;
    resize: vertical;
    margin:18px auto;
    font-size: 17px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    padding: 8px 8px 8px 60px;
    outline: none;
  }

  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:60%;
    margin:20px 0;
  }

  h2{
    font-size: 20px;
    color:var(--generalcolor);
  }

  button{
    &.send{
      background-color: var(--generalcolor);
      color:white;
      font-size: 17px;
      font-weight: 500;
      height:45px;
      border-radius: 5px;
      width:15%;
      margin-top:50px;
      border:2px solid var(--generalcolor);
      cursor: pointer;
      border-radius: 30px;
    }
  }

  a{
    text-decoration: none;
    color:black;

    &.send{
      display: inline-block;
      background-color: var(--generalcolor);
      color:white;
      font-size: 20px;
      font-weight: 500;
      height:30px;
      border-radius: 5px;
      margin-top:80px;
      border:2px solid var(--generalcolor);
      cursor: pointer;
      line-height: 30px;
      padding:10px 40px;
      border-radius: 30px;
    }
  }

  button, a{
    transition: opacity 0.2s linear;
  }

  button:hover, a:hover{
    opacity: 0.9;
  }

  p{
    font-size: 17px;
    line-height: 1.3;
    margin:0;
  }

  #GeneralPage{
    height:100%;
    width:100%;
    background-color: transparent;
  }

  #introduction{
    text-align: left;
    padding:50px 20px;
    background-color: transparent;

    h2{
      background-color: transparent;
      color:var(--generalcolor);
      font-weight: 500;
    }

    p{
      background-color: transparent;
      text-align: justify;
      padding:20px 0;
    }

    img{
      width:100%;
      border-radius: 5px;
    }
  }


  .lpgenericdiv{
    text-align: center;
    padding:80px 10%;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .lpgenericdivContent{
      width: 70%;
    }

    h2{
      color:var(--generalcolor);
      font-weight: 500;
      font-size: 24px;
      padding: 0 0 30px 0;
    }
    
    img{
      width:300px;
      height:300px;
      margin:30px 0;
    }

    h2 + img{
      display: none;
    }

    p, ol, ul{
      text-align: justify;
      width:90%;
      margin:30px auto;

      &.annotation{
        font-weight: 300;
        font-size: 14px;
      }
    }

    li{
      margin:15px 0;
      text-align: left;
      font-size: 16px;
    }

    p, li{
      font-size: 19px;
    }

    .mainColor{
      color:var(--generalcolor);
      font-weight: 600;
    }


    &.promos{
      background-color: rgb(230, 230, 230);

    }

    &.pros{
      p{
        text-align: center;
        margin:40px auto;
        line-height: 1.2;
      }

      img{
        margin:0 0 40px 0;
      }

    }

    form{
      margin:0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin:0 auto;

      input{
        width: 55%;
      }

      .send{
        width: 30%;
      }
    }
  }

  footer{
    margin-top: 50px;
    padding: 30px 20px;
    background-color: var(--generalcolor);

    h2{
      color:white;
      font-size: 20px;
    }

    h3{
      color:white;
      font-size: 16px;
    }
    
    p{
      color:white;
      font-size: 17px;
      text-align: left;
      margin:30px auto;
      left:50%;
      position: relative;
      margin-left: -5%;
      line-height: 20px;

      img{
        height: 20px;
        width:20px;
        margin:10px 15px -4px 0;
      }
    }
  }


}

html{
  scroll-behavior: smooth;
}