@media screen and (max-width:800px){
    #adminPanel{
        margin:0 10px;
        height:80dvh;
        display: flex;
        flex-direction: column;
    
        .apMenu{
            background-color: var(--generalcolor);
            width:100%;
            height:90px;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content:space-around;
            margin:20px auto;
    
            .apMenuItem{
                width: 100%;
                cursor: pointer;
                transition: opacity 0.3s linear;
    
                img{
                    width:35%;
                    height: auto;
                }
                p{
                    color:white;
                    font-size: 12px;
                }
    
                &:hover{
                    opacity: 0.6;
                }
            }
    
        }
    
        .apContent{
            height:100%;
            width:100%;
            margin:0 auto;
            background-color: rgb(233, 233, 233);
            border-radius: 5px;
            overflow-y: auto;
    
            p:not(.apProductItemKeyLabel):not(.dailyQuantitiesHandler p){
                line-height: 1.7;
                font-size: 16px;
                margin:0 auto 20px auto;
                width: 90%;
            }
            
            h2{
                padding:30px 0;
                font-size: 20px;
            }
    
            h3:not(.apProductItemTitle){
                font-size: 18px;
                font-weight: 500;
                color:var(--generalcolor);
                margin:50px auto 10px auto;
            }
    
            form{
                display: flex;
                flex-direction: column;
                width: 90% !important;
                padding:0 5px;
    
                input:not(.promopercentinput):not(.isGlobalInputContainer input):not(.checkboxContainer input):not(.dailyQuantitiesHandler input){
                    margin:20px auto;
                    width: 75%;
                }
    
                .send{
                    margin:40px auto;
                    width:60%;
                }
            }
    
            form:not(.ProductHandlerForm){
                width: 50%;
                margin:20px auto;
            }

            .send{
                height: 45px;
            }
        }
    }
}

@media screen and (min-width:801px){
    #adminPanel{
        margin:4vh 3%;
        height:80vh;
        display: flex;
        flex-direction: row;
    
        .apMenu{
            background-color: var(--generalcolor);
            width:10%;
            height:100%;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content:space-around;
    
            .apMenuItem{
                width: 100%;
                cursor: pointer;
                transition: opacity 0.3s linear;
    
                img{
                    width:35%;
                    height: auto;
                }
                p{
                    color:white;
                    font-size: 18px;
                    margin-top: 10px;
                }
    
                &:hover{
                    opacity: 0.6;
                }
            }
    
        }
    
        .apContent{
            height:100%;
            width:85%;
            margin:0 auto;
            background-color: rgb(233, 233, 233);
            border-radius: 5px;
            overflow-y: auto;
    
            p:not(.apProductItemKeyLabel):not(.dailyQuantitiesHandler p){
                line-height: 1.7;
                font-size: 18px;
                margin-bottom: 20px;
            }
            
            h2{
                padding:30px 0;
            }
    
            h2{
                font-size: 24px;
            }
    
            h3:not(.apProductItemTitle){
                font-size: 20px;
                font-weight: 500;
                color:var(--generalcolor);
                margin:50px auto 10px auto;
            }
    
            form{
                display: flex;
                flex-direction: column;
    
                input:not(.promopercentinput):not(.isGlobalInputContainer input):not(.checkboxContainer input):not(.dailyQuantitiesHandler input){
                    margin:20px auto;
                    width: 80%;
                }
    
                .send{
                    margin:40px auto;
                    width:30%;
                }
            }
    
            form:not(.ProductHandlerForm){
                width: 50%;
                margin:20px auto;
            }
        }
    }
}