@media screen and (max-width:800px){
    #PaymentPage{
        h2{
            font-size: 22px;
            color:var(--generalcolor);
            padding:30px 0;
            font-weight: 500;
        }
    
        h3{
            padding:0 0 30px 0;
            font-weight: 400;
            font-size: 17px;
        }
    
        .row{
            width: 90%;
            margin:20px auto;
            justify-content: space-between;
            align-items: center;
            p{
                font-size: 18px;
                line-height: 100%;
            }
        }
    
        .buyingType{
    
            p{
                width:95%;
                text-align: left;
            }
    
            input{
                height: 30px;
                width:30px;
                padding: 0;
                
            }
        }
    
        .frequencyContainer{
            margin:20px auto;
    
            select{
                height:35px;
                width:60px;
                font-size: 16px;
            }
        }
    
    
        .totalPrice{
            margin-top: 40px;
    
            p{
                font-size: 20px;
                font-weight: 400;
            }
    
            p:last-of-type{
                color: var(--generalcolor);
                font-weight: 500;
            }
    
        }

        .livraison{
            p{
                margin:45px auto 20px auto;
                font-weight: 400;
                line-height: 2;
            }

            label{
                width: 100%;
                input{
                    width: 20px;
                    height: 20px;
                    margin-left: 20px;
                }
                margin-bottom: 40px;
            }
        }
    
        form{
            width:90%;
            margin:30px auto 100px auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            input{
                width:70%;
                margin:20px auto;
            }
    
            .row{
                width: 92%;
                justify-content: space-between;
                margin:20px auto;
    
                input{
                    width:25%;
                    margin:0;
                }
            }

            .totalPrice{
                margin-bottom: 50px;
            }
    
            .StripeElement{
                width:88%;
                border:2px solid var(--generalcolor);
                margin:20px auto;
                border-radius: 5px;
                padding:4px 0;
            }

            .paymentFormBoldText{
                margin:30px auto;
                width:90%;
                font-weight: 500;

                .link{
                    color:black;
                    text-decoration: underline;
                }
            }
    
            .send{
                margin-top: 20px;
            }
    
        }
    }
    
    .summary{
    
        width:90%;
        margin:0 auto;
        background-color: gainsboro;
        overflow-y: auto;
        max-height: 500px;
    
        h4{
            background-color: var(--generalcolor);
            color:white;
            width: 100%;
            padding: 10px 0;
        }
    
        .cartItem{
    
            background-color: rgb(198, 198, 198);
            height:100px;
            width:95%;
            margin:15px auto;
            border-radius: 10px;
    
            img{
                max-width: 20%;
                height: 70%;
                margin-left: 2%;
                max-height: 90%;
            }
    
            .cartItemInfos{
                width:70%;
                margin-right: 5%;
                height: 70%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
    
                h5{
                    font-size: 16px;
                    font-weight: 400;
                    padding: 0;
                    margin: 0;
                }
    
                .row{
                    justify-content: space-around;
                    padding: 0;
                    margin: 0;
                    
                    p:last-of-type{
                        color:var(--generalcolor);
                        font-weight: 500;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:801px){
    #PaymentPage{

        width: 50%;
        margin:0 auto;

        h2{
            font-size: 22px;
            color:var(--generalcolor);
            padding:30px 0;
            font-weight: 500;
        }
    
        h3{
            padding:0 0 30px 0;
            font-weight: 400;
            font-size: 17px;
        }
    
        .row{
            width: 90%;
            margin:20px auto;
            justify-content: space-between;
            align-items: center;
            p{
                font-size: 18px;
                line-height: 100%;
            }
        }
    
        .buyingType{
    
            p{
                width:95%;
                text-align: left;
            }
    
            input{
                height: 30px;
                width:30px;
                padding: 0;
                
            }
        }
    
        .frequencyContainer{
            margin:20px auto;
    
            select{
                height:35px;
                width:60px;
                font-size: 16px;
            }
        }
    
    
        .totalPrice{
            margin-top: 40px;
    
            p{
                font-size: 20px;
                font-weight: 400;
            }
    
            p:last-of-type{
                color: var(--generalcolor);
                font-weight: 500;
            }
    
        }
    
        form{
            width:90%;
            margin:30px auto 100px auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            input{
                width:50%;
                margin:20px auto;
            }
    
            .row{
                width: 92%;
                justify-content: space-between;
                margin:30px auto;
    
                input{
                    width:25%;
                    margin:0;
                }
            }

            .totalPrice{
                margin:30px auto 50px auto;
            }
    
            .StripeElement{
                width:58%;
                border:2px solid var(--generalcolor);
                margin:20px auto;
                border-radius: 5px;
                padding:4px 0;
            }

            .paymentFormBoldText{
                margin:30px auto;
                width:50%;
                font-weight: 500;

                .link{
                    color:black;
                    text-decoration: underline;
                }
            }
    
            .send{
                margin-top: 20px;
                width: 150px;
            }
    
        }

        .livraison{
            p{
                margin:45px auto 20px auto;
                font-weight: 400;
                line-height: 2;
            }

            label{
                width: 100%;
                input{
                    width: 20px;
                    height: 20px;
                    margin-left: 20px;
                }
                margin-bottom: 40px;
            }
        }

    }
    
    .summary{
    
        width:90%;
        margin:0 auto;
        background-color: gainsboro;
        overflow-y: auto;
        max-height: 500px;
    
        h4{
            background-color: var(--generalcolor);
            color:white;
            width: 100%;
            padding: 10px 0;
        }
    
        .cartItem{
    
            background-color: rgb(198, 198, 198);
            height:125px;
            width:95%;
            margin:15px auto;
            border-radius: 10px;
    
            img{
                max-width: 20%;
                height: 90%;
                margin-left: 2%;
                max-height: 90%;
            }
    
            .cartItemInfos{
                width:70%;
                margin-right: 5%;
                height: 70%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
    
                h5{
                    font-size: 18px;
                    font-weight: 400;
                    padding: 0;
                    margin: 0;
                }
    
                .row{
                    justify-content: space-around;
                    padding: 0;
                    margin: 0;
                    
                    p:last-of-type{
                        color:var(--generalcolor);
                        font-weight: 500;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}