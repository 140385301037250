@media screen and (max-width:800px){
    .presentationPage, .otherTextPage{
        h2{
            padding:50px;
            font-size: 20px;
        }

        img{
            width: 90%;
            margin: 0 auto 50px auto;
            border-radius: 5px;
        }
    
        p{
            margin:0 auto 50px auto;
            text-align: justify;
            width:90%;
            line-height: 1.5;

            .bold{
                font-weight: 600;
            }
            
        }
    }
}

@media screen and (min-width:801px){
    .presentationPage, .otherTextPage{
        h2{
            padding: 0;
            margin: 0;
            padding:50px 0;
            font-size: 22px;
        }

        img{
            width: 70%;
            margin: 0 auto 50px auto;
            border-radius: 5px;
        }
    
        p{
            margin:0 auto 50px auto;
            text-align: justify;
            width:60%;
            line-height: 1.5;

            .bold{
                font-weight: 600;
            }
            
        }
    }
}