@media screen and (max-width:800px){
    .apProducts{
        width: 100%;
        margin:0 auto;
    
        input[name="search"]{
            margin:60px auto 0 auto;
        }
    }
}

@media screen and (min-width:801px){
    .apProducts{
        width: 80%;
        margin:0 auto;
    
        input[name="search"]{
            margin:60px auto 0 auto;
        }
    }
}