@media screen and (max-width:800px){
  #productLinesPresentation{
    padding:35px 0;
  
    h2{
      font-size: 22px;
      margin-bottom: 40px;
    }
  
    .productLineItem{
      padding:35px 20px;
    
      h3{
        color: var(--generalcolor);
        font-size: 21px;
        margin-top: 0px;
        padding: 10px 0 30px 0;
      }
    
      img{
        height:200px;
        margin:25px 0;
      }
    
      p{
        text-align: justify;
      }
  
      &.myfood{
        background-image: linear-gradient(to bottom right, rgb(219, 234, 239) , rgb(233, 211, 233));
      }
  
      &.klicker{
        background-image: linear-gradient(to bottom right, rgb(36, 36, 36), #605c55);
        p{color:white;}
        h3{color:rgb(255, 255, 255)}
      }

      &.treats{
        background-image: linear-gradient(to bottom right,rgb(245, 234, 227), rgb(220, 193, 177));
        p{color:black;}
        h3{color:black}
      }

      &.accessory{
        background-image: linear-gradient(to bottom right, rgb(207, 207, 207), #bcbcbc);
        p{color:black;}
        h3{color:black}
      }

      &.accessoryPremium{
        background-image: linear-gradient(to bottom right, rgb(13, 13, 13),rgb(44, 44, 44));
        p{color:rgb(255, 255, 126);}
        h3{color:rgb(255, 255, 126);}
      }
  
      &.mystic{
        //background-image: linear-gradient(to bottom right, #6f9ea4 , #bbd3d6);
        background-image: linear-gradient(to bottom right,#22616a, #7faab0);
        display: none;
  
        p{color:white;}
        h3{color:rgb(255, 255, 255)}
  
      }
    }
  }
}

@media screen and (min-width:801px){
  #productLinesPresentation{
    padding:80px 0;
  
    h2{
      font-size: 22px;
      margin-bottom: 40px;
    }
  
    .productLineItem{
      padding:100px 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  
      &.myfood{
        background-image: linear-gradient(to bottom right, rgb(219, 234, 239) , rgb(233, 211, 233));
      }
  
      &.klicker{
        background-image: linear-gradient(to bottom right, rgb(36, 36, 36), #605c55);
      }

      &.treats{
        background-image: linear-gradient(to bottom right,rgb(245, 234, 227), rgb(220, 193, 177));
      }

      &.accessory{
        background-image: linear-gradient(to bottom right, rgb(232, 232, 232), #cecece);
      }

      &.accessoryPremium{
        background-image: linear-gradient(to bottom right, rgb(13, 13, 13),rgb(44, 44, 44));
      }

      .imgContainer{

        max-height: 100%;
        width: 30%;

        img{
          max-height: 300px;
          max-width: 100%;
        }
      }


      .productLineItemContent{

        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3{
          color: var(--generalcolor);
          font-size: 24px;
          margin-top: 0px;
          padding: 0 0 50px 0;
        }
      
        p{
          text-align: justify;
          font-size: 20px;
          width:70%;
          margin:0 auto;
        }
    
        &.klicker{
          p{color:white;}
          h3{color:rgb(255, 255, 255)}
        }
  
        &.treats{
          p{color:black;}
          h3{color:black}
        }
  
        &.accessory{
          p{color:black;}
          h3{color:black}
        }
  
        &.accessoryPremium{
          p{color:rgb(255, 255, 126);}
          h3{color:rgb(255, 255, 126);}
        }
  
      }

    }
  }
}