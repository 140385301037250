@media screen and (max-width:800px){
    .apOrders{
    
        .apOrderItem, .apPreviousOrder{
            background-color: rgb(216, 216, 216);
            width:90%;
            margin:40px auto;
            border-radius: 5px;
    
            .apOrderTitle{
                background-color: var(--generalcolor);
                border-radius: 5px 5px 0 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding:15px;
    
                h4{
                    height: 100%;
                    line-height: 100%;
                    color:white;
                    font-size: 16px;
                    font-weight: 500;
                    
                    &:first-of-type{
                        flex:1;
                        text-align: left;
                    }

                    &:last-of-type{
                        flex: 0.3;
                        text-align: right;
                    }
                }
            }
    
            .summary{
                background-color: transparent;
    
                .cartItem{
                    width: 100%;

                    .row{
                        margin-top: 8px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:801px){
    .apOrders{
        .apOrderItem, .apPreviousOrder{
            background-color: rgb(216, 216, 216);
            width:60%;
            margin:40px auto;
            border-radius: 5px;
    
            .apOrderTitle{
                background-color: var(--generalcolor);
                border-radius: 5px 5px 0 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding:15px;
    
                h4{
                    height: 100%;
                    line-height: 100%;
                    color:white;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
    
            .summary{
                background-color: transparent;
    
                .cartItem{
                    width: 100%;
                }
            }
        }
    }
}