@media screen and (max-width:800px){
  .ProductDetailTab{
    width:100%;  
  
  
    .tabTitle{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding:20px 15px;
      background-color: #f0f0f0;
      border:1px solid gainsboro;
  
      p{
        font-size: 18px;
        font-weight: 500;
        color:var(--generalcolor);
      }
  
      .openIcon{
        width:20px;
        height:20px;
        transform: rotate(180deg);
  
        &.visible{
          transform: rotate(360deg);
        }
      }
    }
  
    .content{
      padding:0;
      height: 0px;
      opacity: 0;
      pointer-events: none;
      transition: padding-top 0.1s linear, padding-bottom 0.1s linear;
  
      &.visible{
        padding:20px;
        height: auto;
        opacity: 1;
      }
  
      p{
        font-size: 15px;
        line-height: 1.6;
        text-align: justify;
        white-space: pre-line;
      }

      img{
        margin:40px 0;
        width: 95%;
      }

  
      .composition{
  
        h2{
          padding:30px 0;
          font-size: 18px;
          font-weight: 500;
        }
  
        table{
          margin:0 auto 30px auto;
          border-spacing: 0;
          border-collapse: collapse;
          width:80%;
  
          td{
            border:1px solid black;
            margin:0;
            padding:10px;
            width:50%;
            font-size: 17px;
          }
        }
  
      }
  
      .transition{
        h3{
          padding: 20px 0 30px 0;
          font-size: 17px;
          font-weight: 400;
        }
  
        .stepListContainer{
  
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
  
          .stepContainer{
            margin:10px 0;
            width:44%;
            display: flex;
            flex-direction: column;
            align-items: center;
            *{
              margin:15px 0;
            }
  
            .circle{
              width: 70%;
              aspect-ratio: 1/1;
              display: block;
              border-radius: 50%;
              background-color: var(--generalcolor);
  
              &.step1{
                  background-image:
                      linear-gradient(180deg, transparent 50%, var(--stepCircleColor) 50%),
                      linear-gradient(90deg, var(--stepCircleColor) 50%, transparent 50%);
              }
  
              &.step2{
                background-image:
                    linear-gradient(270deg, transparent 50%, var(--stepCircleColor) 50%),
              }
  
              &.step3{
                  background-image:
                      linear-gradient(180deg, transparent 50%, var(--generalcolor) 50%),
                      linear-gradient(90deg, var(--stepCircleColor) 50%, transparent 50%);
              }
            }
  
            p{
              margin:15px 5px;
              line-height: 1.3;
              text-align: justify;
              font-size: 14px;
  
              .bold{
                font-weight: 500;
                color:var(--generalcolor);
              }
            }
          }
        }
      }
  
    }
  
  }
}

@media screen and (min-width:801px){
  .ProductDetailTab{
    width:100%;  
  
  
    .tabTitle{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding:20px 50px;
      background-color: #f0f0f0;
      border:1px solid gainsboro;
  
      p{
        font-size: 18px;
        font-weight: 500;
        color:var(--generalcolor);
      }
  
      .openIcon{
        width:20px;
        height:20px;
        transform: rotate(180deg);
  
        &.visible{
          transform: rotate(360deg);
        }
      }
    }
  
    .content{
      padding:0;
      height: 0px;
      opacity: 0;
      pointer-events: none;
      transition: padding-top 0.1s linear, padding-bottom 0.1s linear;
  
      &.visible{
        padding:50px;
        height: auto;
        opacity: 1;
      }
  
      p{
        font-size: 18px;
        line-height: 1.7;
        text-align: justify;
        white-space: pre-line;
      }

      img{
        margin:40px;
      }
  
      .composition{
  
        h2{
          padding:30px 0;
          font-size: 20px;
          font-weight: 500;
        }

        p{
          width:50%;
          margin:0 auto 20px auto;
        }
  
        table{
          margin:0 auto 30px auto;
          border-spacing: 0;
          border-collapse: collapse;
          width:40%;
  
          td{
            border:1px solid black;
            margin:0;
            padding:20px 10px;
            width:50%;
            font-size: 17px;
          }
        }
  
      }
  
      .transition{
        h3{
          padding: 20px 0 30px 0;
          font-size: 20px;
          font-weight: 400;
        }
  
        .stepListContainer{
  
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          width:80%;
          margin:0 auto;
  
          .stepContainer{
            margin:50px 0;
            width:14%;
            display: flex;
            flex-direction: column;
            align-items: center;
            *{
              margin:15px 0;
            }
  
            .circle{
              width: 80%;
              aspect-ratio: 1/1;
              display: block;
              border-radius: 50%;
              background-color: var(--generalcolor);
  
              &.step1{
                  background-image:
                      linear-gradient(180deg, transparent 50%, var(--stepCircleColor) 50%),
                      linear-gradient(90deg, var(--stepCircleColor) 50%, transparent 50%);
              }
  
              &.step2{
                background-image:
                    linear-gradient(270deg, transparent 50%, var(--stepCircleColor) 50%),
              }
  
              &.step3{
                  background-image:
                      linear-gradient(180deg, transparent 50%, var(--generalcolor) 50%),
                      linear-gradient(90deg, var(--stepCircleColor) 50%, transparent 50%);
              }
            }
  
            p{
              margin:15px 5px;
              line-height: 1.3;
              text-align: justify;
              font-size: 19px;
  
              .bold{
                font-weight: 500;
                color:var(--generalcolor);
              }
            }
          }
        }
      }
  
    }
  
  }
}