@media screen and (max-width:800px){
  #menu{
    border-bottom: 1px solid gainsboro;
    margin:0;

    .topMenuContainer{
      width:100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height:90px;
  
      .logo{
        height:70px;
        margin: 0 10px;
        cursor: pointer;
    
        img{
          height:100%;
        }
      }

      .menuRightContainer{

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .menuIconButton{
          background-color: transparent;
          border:none;
          cursor: pointer;
          height:30px;
          width: 40px;
          margin:0 10px;
  
          img{
            height: 100%;
          }
        }
    
        .menuButton{
          border: none;
          cursor: pointer;
          background-color: transparent;
          font-size: 40px;
          padding: 0;
          margin:0 10px;
          color:black;
          width:50px;
        }
      }
    }

    .summaryContainer{

      width:80%;
      margin:0 auto;
      background-color: gainsboro;
      overflow-y: auto;
      max-height: 500px;
      position: absolute;
      top: 110px;
      right:10px;
      border:2px solid white;
      transition:opacity 0.2s linear;
      border-radius: 5px;


      &.visible{
        opacity:1;
        pointer-events: all;
        max-height: 500px;
      }
  
      &.hidden{
        opacity:0;
        pointer-events: none;
      }
      

      h4{
          background-color: var(--generalcolor);
          color:white;
          width: 100%;
          padding: 10px 0;
      }

      .cartItem{

          background-color: rgb(198, 198, 198);
          height:100px;
          width:95%;
          margin:15px auto;
          border-radius: 10px;

          img{
              max-width: 20%;
              height: 70%;
              margin-left: 2%;
          }

          .cartItemInfos{
              width:70%;
              margin-right: 5%;
              height: 70%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              h5{
                  font-size: 18px;
                  font-weight: 400;
                  padding: 0;
                  margin: 0;
              }

              .row{
                  justify-content: space-around;
                  padding: 0;
                  margin: 0;
                  
                  p:last-of-type{
                      color:var(--generalcolor);
                      font-weight: 500;
                      font-size: 20px;
                  }
              }
          }

          .deleteItem{
            height:80%;
            width:30px;
            margin-right: 8px;
            background-color: var(--generalcolor);
            color:white;
            cursor: pointer;
            border: none;
            border-radius: 0 5px 5px 0;
          }
      }

      .send{
        margin:20px auto;
      }
    }
  
    .pageMenu{
      display: flex;
      flex-direction: column;
      display: flex;
      transition:margin 0.2s linear;
  
      &.visible{
        height:auto;
        opacity:1;
        margin:20px 0;
        pointer-events: all;
      }
  
      &.hidden{
        height:0;
        opacity:0;
        margin:0;
        pointer-events: none;
      }
      
      a{
        margin:10px 0;
        text-decoration: none;
        color:black;
        font-weight: 300;
      }
    }
  
  
  
  
  }
}

@media screen and (min-width:801px){
  #menu{
    border-bottom: 1px solid gainsboro;
    margin:0;

    .topMenuContainer{
      width:100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height:90px;
  
      .logo{
        height:75px;
        margin: 0 25px;
        cursor: pointer;
    
        img{
          height:100%;
        }
      }

      .menuRightContainer{

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .menuIconButton{
          background-color: transparent;
          border:none;
          cursor: pointer;
          height:30px;
          width: 40px;
          margin:0 10px;
  
          img{
            height: 100%;
          }
        }
    
        .menuButton{
          border: none;
          cursor: pointer;
          background-color: transparent;
          font-size: 40px;
          padding: 0;
          margin:0 10px;
          color:black;
          width:50px;
        }
      }
    }

    .summaryContainer{

      width:35%;
      margin:0 auto;
      background-color: gainsboro;
      overflow-y: auto;
      max-height: 500px;
      position: absolute;
      top: 100px;
      right:10px;
      border:2px solid white;
      transition:opacity 0.2s linear;
      border-radius: 5px;

      &.visible{
        opacity:1;
        pointer-events: all;
        max-height: 500px;
      }
  
      &.hidden{
        opacity:0;
        pointer-events: none;
      }
      

      h4{
          background-color: var(--generalcolor);
          color:white;
          width: 100%;
          padding: 10px 0;
      }

      .cartItem{

          background-color: rgb(198, 198, 198);
          height:120px;
          width:95%;
          margin:15px auto;
          border-radius: 10px;

          img{
              max-width: 20%;
              height: 90%;
              margin-left: 2%;
              max-height: 90%;
          }

          .cartItemInfos{
              width:70%;
              margin-right: 5%;
              height: 70%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              h5{
                  font-size: 18px;
                  font-weight: 400;
                  padding: 0;
                  margin: 0;
              }

              .row{
                  justify-content: space-around;
                  padding: 0;
                  margin: 0;
                  
                  p:last-of-type{
                      color:var(--generalcolor);
                      font-weight: 500;
                      font-size: 20px;
                  }
              }
          }

          .deleteItem{
            height:80%;
            width:30px;
            margin-right: 8px;
            background-color: var(--generalcolor);
            color:white;
            cursor: pointer;
            border: none;
            border-radius: 0 5px 5px 0;
          }
      }

      .send{
        margin:20px auto;
        width: 150px;
      }
    }
  
    .pageMenu{
      display: flex;
      flex-direction: column;
      display: flex;
      transition:margin 0.2s linear;
  
      &.visible{
        height:auto;
        opacity:1;
        margin:20px 0;
        pointer-events: all;
      }
  
      &.hidden{
        height:0;
        opacity:0;
        margin:0;
        pointer-events: none;
      }
      
      a{
        margin:10px 0;
        text-decoration: none;
        color:black;
        font-weight: 300;
      }
    }
  
  
  
  
  }
}